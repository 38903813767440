import * as React from 'react'
import styled, { css } from 'styled-components'
import { colors } from '../../styleConstants'
import { RawLink, Props as LinkProps } from './Link'

const base = css`
  display: inline-flex;
  align-items: center;
  box-sizing: border-box;
  font-family: inherit;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
  border-radius: 3px;
  padding: 0.5rem 0.75rem;
  cursor: pointer;
  user-select: none;
  outline: none;
  text-decoration: none;
  border-style: none;
  border-width: 1px;

  &:disabled {
    opacity: 0.5;
  }

  [data-icon='left'] {
    display: inline-block;
    margin-right: 1rem;
  }

  [data-icon='right'] {
    display: inline-block;
    margin-left: 1rem;
  }
`

const look = {
  primary: css`
    color: ${colors.white};
    background-color: ${colors.primary};
    border-color: ${colors.primaryLight};
    transition: filter 0.3s;

    &:hover,
    &:focus {
      background-color: ${colors.primaryLight};
    }

    &:active {
      filter: brightness(1.5);
      transition: none;
    }
  `,
  white: css`
    color: ${colors.secondaryDark};
    background-color: ${colors.white};
    border-color: ${colors.gray300};
    transition: filter 0.3s;

    &:hover,
    &:focus {
      background-color: ${colors.gray50};
    }

    &:active {
      filter: brightness(0.9);
      transition: none;
    }
  `,
  dark: css`
    color: ${colors.white};
    background-color: ${colors.gray800};
    border-color: transparent;
    transition: background-color 0.3s;

    &:hover,
    &:focus {
      background-color: ${colors.gray700};
    }

    &:active {
      background-color: ${colors.gray600};
      transition: none;
    }
  `,
  transparent: css`
    color: ${colors.white};
    background-color: transparent;
  `,
}

export interface Props {
  readonly look: 'primary' | 'white' | 'dark' | 'transparent'
  readonly iconLeft?: JSX.Element
  readonly iconRight?: JSX.Element
  readonly bordered?: boolean
  readonly href?: LinkProps['href']
  readonly route?: LinkProps['route']
}

const BaseButton = styled.button<Props>`
  ${base}
  ${props => look[props.look]}

  border-style: ${props => (props.bordered ? 'solid' : 'none')};
`

const LinkButton = styled(({ look, iconLeft, iconRight, bordered, ...rest }: Props & LinkProps) => (
  <RawLink {...rest} />
))`
  ${base}
  ${props => look[props.look]}

  border-style: ${props => (props.bordered ? 'solid' : 'none')};
`

export function Button({
  iconLeft,
  iconRight,
  href,
  route,
  children,
  ...rest
}: Props & React.ButtonHTMLAttributes<any>) {
  if (href || route) {
    const props = { ...rest, href, route }
    return (
      <LinkButton {...props}>
        {iconLeft && React.cloneElement(iconLeft, { 'data-icon': 'left' })}
        {children}
        {iconRight && React.cloneElement(iconRight, { 'data-icon': 'right' })}
      </LinkButton>
    )
  }
  return (
    <BaseButton {...rest}>
      {iconLeft && React.cloneElement(iconLeft, { 'data-icon': 'left' })}
      {children}
      {iconRight && React.cloneElement(iconRight, { 'data-icon': 'right' })}
    </BaseButton>
  )
}
