export const colors = {
  black: '#000000',
  gray900: '#212121',
  gray800: '#424242',
  gray700: '#616161',
  gray600: '#757575',
  gray500: '#9E9E9E',
  gray400: '#BDBDBD',
  gray300: '#E0E0E0',
  gray200: '#EEEEEE',
  gray100: '#F5F5F5',
  gray50: '#FAFAFA',
  white: '#FFFFFF',
  primary: '#49B675',
  primaryLight: '#77c897',
  primaryDark: '#3A925E',
  secondary: '#B49531',
  secondaryLight: '#D8BF6F',
  secondaryExtraLight: '#F3F2EB',
  secondaryDark: '#5A5129',
  brown: '#7E6C5C',
  brownDark: '#51473D',
  facebook: {
    base: '#1877f2',
    hover: '#1887ff',
    press: '#1877c7',
  },
  line: {
    base: '#00C300',
    hover: '#00E000',
    press: '#00B300',
  },
  error: '#B00020',
}

export const sizes = {
  pageMaxWidth: '1200px',
  pageMinWidth: '320px',
  navigationDrawerWidth: '200px',
  headerHeight: '96px',
  bottomNavigationHeight: '64px',
  contentWidth: '1100px',
  sideMargin: '50px',
}

export const devices = {
  mobile: '(max-width: 767px)',
}

// See https://material-components.github.io/material-components-web-catalog/#/component/elevation
export const boxShadows = {
  z1: '0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12)',
  z2: '0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)',
  z4: '0 2px 4px -1px rgba(0,0,0,.2), 0 4px 5px 0 rgba(0,0,0,.14), 0 1px 10px 0 rgba(0,0,0,.12)',
  z8: '0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12)',
  z16: '0 8px 10px -5px rgba(0,0,0,.2), 0 16px 24px 2px rgba(0,0,0,.14), 0 6px 30px 5px rgba(0,0,0,.12)',
  z24: '0 11px 15px -7px rgba(0,0,0,.2), 0 24px 38px 3px rgba(0,0,0,.14), 0 9px 46px 8px rgba(0,0,0,.12)',
}

export const zIndices = {
  dialog: 9000,
  navigation: 8000,
  dropdown: 7000,
  header: 6000,
}

export const fontFamilies = {
  serif: '"EB Garamond", serif',
  sansSerif: '"Noto Sans JP", sans-serif',
}
