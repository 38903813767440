import { I18nCatalogue } from './types'

const en: I18nCatalogue = {
  header: {
    language: 'Language',
    languageDialogTitle: 'Select your language',
    signIn: 'Sign in',
    signUp: 'Sign up',
    signOut: 'Sign out',
    profile: 'Profile',
  },
  footer: {
    help: 'Help',
    terms: 'Terms',
    privacy: 'Privacy',
  },
  navigation: {
    home: 'Home',
    explore: 'Explore',
    calendar: 'Calendar',
    reservationHistory: 'Reservation History',
    inbox: 'Inbox',
    profile: 'Profile',
    conversations: 'Conversations',
    adminDashboard: 'Admin',
    salonsEdit: 'Edit Salon',
    salonsMember: 'Salon Member',
  },
  cancel: 'Cancel',
  done: 'Done',
  visited: 'Visited',
  abort: 'Abort',
  delete: 'Delete',
  reload: 'Reload',
  edit: 'Edit',
  save: 'Save',
  close: 'Close',
  detail: 'See details',
  end: 'End',
  reserveCteated: 'Reservation completion time',
  dayOfWeek: {
    sunday: 'Sun',
    monday: 'Mon',
    tuesday: 'Tue',
    wednesday: 'Wed',
    thursday: 'Thu',
    friday: 'Fri',
    saturday: 'Sat',
  },
  ja: 'ja',
  en: 'en',
  creditSettlement: { title: 'Credit Settlement', failedReadCardInfo: 'Failed read card info' },
  excludingTax: 'in.',
  minute: 'minute',
}

export default en
