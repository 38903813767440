import { Language, I18nDirectory, PreferredLanguageKeyInCookie } from './types'
import { NextPageContext } from 'next'
import Cookies from 'universal-cookie'
import { Me } from '../types/me'

export const defaultLanguage = Language.English

export async function loadCatalogue<T>(dir: I18nDirectory, me?: Me, req?: NextPageContext['req']): Promise<T> {
  const language = me ? me.user.preferredLanguage : getPreferredLanguage(req)
  return await import(`./${dir}/${language}`).then(mod => mod.default)
}

export function getPreferredLanguage(req?: NextPageContext['req']): Language {
  const lang = getLanguage(req)
  if (!lang) {
    return defaultLanguage
  }
  switch (true) {
    case lang.startsWith('ja'):
      return Language.Japanese
    case lang.startsWith('en'):
      return Language.English
    default:
      return defaultLanguage
  }
}

function getLanguage(req?: NextPageContext['req']): string | undefined {
  if (typeof navigator === 'object') {
    const cookies = new Cookies()
    const lang = cookies.get(PreferredLanguageKeyInCookie)
    if (lang) {
      return lang
    }
    return navigator.language
  }
  if (req) {
    const cookies = new Cookies(req.headers.cookie)
    const lang = cookies.get(PreferredLanguageKeyInCookie)
    if (lang) {
      return lang
    }
    return req.headers['accept-language']
  }
  return undefined
}
