import { ParsedUrlQuery } from 'querystring'

export interface Route {
  readonly href: {
    readonly pathname: string
    readonly query?: ParsedUrlQuery
  }
  readonly as?: string
}

interface RouteFactory<T = string | number> {
  readonly pathname: string
  readonly getRoute: (...params: T[]) => Route
}

interface Routes {
  readonly root: RouteFactory
  readonly signUp: RouteFactory
  readonly signIn: RouteFactory
  readonly profile: RouteFactory
  readonly explore: RouteFactory
  readonly goodbye: RouteFactory
  readonly stylists: RouteFactory
  readonly salons: RouteFactory
  readonly salonsEdit: RouteFactory
  readonly salonsMember: RouteFactory
  readonly reservation: RouteFactory
  readonly reservationHistory: RouteFactory
  readonly adminDashboard: RouteFactory
  readonly adminReservedSpots: RouteFactory
  readonly adminSelectReserveType: RouteFactory
  readonly adminSalons: RouteFactory
  readonly adminSalonsMember: RouteFactory
  readonly adminSalonsEdit: RouteFactory
  readonly adminStylists: RouteFactory
  readonly adminStylistsEdit: RouteFactory
  readonly adminServiceConfig: RouteFactory
  readonly adminSpecialSpots: RouteFactory
  readonly adminUsers: RouteFactory
  readonly adminReservations: RouteFactory
  readonly adminConciergeRequests: RouteFactory
  readonly privacyPolicy: RouteFactory
  readonly privacyPolicy_EN: RouteFactory
  readonly serviceTerm: RouteFactory
  readonly serviceTerm_EN: RouteFactory
}

export const routeFactories: Routes = {
  root: {
    pathname: '/',
    getRoute: () => ({ href: { pathname: '/' } }),
  },
  signUp: {
    pathname: '/auth',
    getRoute: () => ({ href: { pathname: '/auth', query: { page: 'sign-up' } }, as: '/sign-up' }),
  },
  signIn: {
    pathname: '/auth',
    getRoute: () => ({ href: { pathname: '/auth', query: { page: 'sign-in' } }, as: '/sign-in' }),
  },
  profile: {
    pathname: '/profile',
    getRoute: () => ({ href: { pathname: '/profile' } }),
  },
  explore: {
    pathname: '/explore',
    getRoute: () => ({ href: { pathname: '/explore' } }),
  },
  goodbye: {
    pathname: '/goodbye',
    getRoute: () => ({ href: { pathname: '/goodbye' } }),
  },
  stylists: {
    pathname: '/stylists',
    getRoute: id => ({ href: { pathname: '/stylists', query: { id: id.toString() } }, as: `/stylists/${id}` }),
  },
  salons: {
    pathname: '/salons',
    getRoute: id => ({ href: { pathname: '/salons', query: { id: id.toString() } }, as: `/salons/${id}` }),
  },
  salonsEdit: {
    pathname: '/salons/:id',
    getRoute: id => ({
      href: { pathname: '/salons/:id', query: { id: id.toString() } },
      as: `/salons/${id}/edit`,
    }),
  },
  salonsMember: {
    pathname: '/salons/:id',
    getRoute: id => ({
      href: { pathname: '/salons/:id', query: { id: id.toString() } },
      as: `/salons/${id}/member`,
    }),
  },
  reservation: {
    pathname: '/reservation',
    getRoute: id => ({ href: { pathname: '/reservation', query: { id: id.toString() } }, as: `/reservation/${id}` }),
  },
  reservationHistory: {
    pathname: '/reservation-history',
    getRoute: () => ({ href: { pathname: '/reservation-history' } }),
  },
  adminDashboard: {
    pathname: '/admin',
    getRoute: () => ({ href: { pathname: '/admin' } }),
  },
  adminReservedSpots: {
    pathname: '/admin/reservedSpots',
    getRoute: () => ({ href: { pathname: '/admin/reservedSpots' } }),
  },
  adminSelectReserveType: {
    pathname: '/admin/select-reserve-type',
    getRoute: () => ({ href: { pathname: '/admin/select-reserve-type' } }),
  },
  adminSalons: {
    pathname: '/admin/salons',
    getRoute: () => ({ href: { pathname: '/admin/salons' } }),
  },
  adminSalonsMember: {
    pathname: '/admin/salons/:id',
    getRoute: id => ({
      href: { pathname: '/admin/salons/:id', query: { id: id.toString() } },
      as: `/admin/salons/${id}/member`,
    }),
  },
  adminSalonsEdit: {
    pathname: '/admin/salons/:id',
    getRoute: id => ({
      href: { pathname: '/admin/salons/:id', query: { id: id.toString() } },
      as: `/admin/salons/${id}/edit`,
    }),
  },
  adminStylists: {
    pathname: '/admin/stylists',
    getRoute: () => ({ href: { pathname: '/admin/stylists' } }),
  },
  adminStylistsEdit: {
    pathname: '/admin/stylists/:id',
    getRoute: id => ({
      href: { pathname: '/admin/stylists/:id', query: { id: id.toString() } },
      as: `/admin/stylists/${id}/edit`,
    }),
  },
  adminServiceConfig: {
    pathname: '/admin/config',
    getRoute: () => ({ href: { pathname: '/admin/config' } }),
  },
  adminSpecialSpots: {
    pathname: '/admin/special-spots',
    getRoute: () => ({ href: { pathname: '/admin/special-spots' } }),
  },
  adminUsers: {
    pathname: '/admin/users',
    getRoute: () => ({ href: { pathname: '/admin/users' } }),
  },
  adminReservations: {
    pathname: '/admin/reservations',
    getRoute: () => ({ href: { pathname: '/admin/reservations' } }),
  },
  adminConciergeRequests: {
    pathname: '/admin/concierge-requests',
    getRoute: () => ({ href: { pathname: '/admin/concierge-requests' } }),
  },
  privacyPolicy: {
    pathname: '/privacy-policy',
    getRoute: () => ({ href: { pathname: '/privacy-policy' } }),
  },
  privacyPolicy_EN: {
    pathname: '/privacy-policy-en',
    getRoute: () => ({ href: { pathname: '/privacy-policy-en' } }),
  },
  serviceTerm: {
    pathname: '/service-term',
    getRoute: () => ({ href: { pathname: '/service-term' } }),
  },
  serviceTerm_EN: {
    pathname: '/service-term-en',
    getRoute: () => ({ href: { pathname: '/service-term-en' } }),
  },
  // post: {
  //   pathname: '/post',
  //   getRoute: id => ({ href: { pathname: '/post', query: { id: id.toString() }, as: `/post/${id}` } }),
  // }, // NOTE: This is just an example
}

interface APIRoutes {
  readonly csrfToken: APIRoute
  readonly signOut: APIRoute
  readonly resetPassword: {
    readonly request: APIRoute
    readonly verifyAndUpdate: APIRoute
  }
  readonly me: {
    readonly root: APIRoute
    readonly email: APIRoute
    readonly name: APIRoute
    readonly preferredLanguage: APIRoute
    readonly image: APIRoute
    readonly birthday: APIRoute
    readonly phoneNumber: APIRoute
    readonly address: APIRoute
    readonly password: APIRoute
    readonly favorite: APIRoute
    readonly favoriteId: APIRoute
  }
  readonly signInWithPassword: APIRoute
  readonly signUpWithPassword: APIRoute
  readonly salonsId: APIRoute
  readonly salonAreas: APIRoute
  readonly salons: APIRoute
  readonly salonStar: APIRoute
  readonly salonStarId: APIRoute
  readonly salonLogoImage: APIRoute
  readonly salonTopImage: APIRoute
  readonly salonMemberships: APIRoute
  readonly salonMembershipsId: APIRoute
  readonly salonMembershipRole: APIRoute
  readonly stylistsId: APIRoute
  readonly stylists: APIRoute
  readonly stylistStar: APIRoute
  readonly stylistStarId: APIRoute
  readonly stylistImage: APIRoute
  readonly styleImage: APIRoute
  readonly stylistReservedSpots: APIRoute
  readonly stylistReservedSpotsId: APIRoute
  readonly stylistReservedSpotsPolicies: APIRoute
  readonly reserve: APIRoute
  readonly reserveId: APIRoute
  readonly reserveFee: APIRoute
  readonly stylistReserve: APIRoute
  readonly updateStylistReserve: APIRoute
  readonly adminReserveIndex: APIRoute
  readonly adminReserve: APIRoute
  readonly adminReservedSpots: APIRoute
  readonly adminUpdateReleaseTime: APIRoute
  readonly adminConciergeRequests: APIRoute
  readonly menu: APIRoute
  readonly menuId: APIRoute
  readonly specialSpots: APIRoute
  readonly specialSpotsId: APIRoute
  readonly conciergeMenus: APIRoute
  readonly conciergeHairTypes:APIRoute
  readonly conciergeHairLengths:APIRoute
  readonly contactConcierge: APIRoute

  readonly user: APIRoute
  readonly searchUser: APIRoute
  readonly sendNoticeMailToUsers: APIRoute
  readonly users: APIRoute
}

export type APIRoute = (...params: string[]) => string

export const apiRoutes: APIRoutes = {
  csrfToken: () => '/api/csrf_token',
  signOut: () => '/api/sign_out',
  resetPassword: {
    request: () => '/api/reset_password',
    verifyAndUpdate: () => '/api/reset_password/verify_and_update',
  },
  me: {
    root: () => '/api/me',
    email: () => '/api/me/email',
    name: () => '/api/me/name',
    preferredLanguage: () => '/api/me/preferred_language',
    image: () => '/api/me/image',
    birthday: () => '/api/me/birthday',
    phoneNumber: () => '/api/me/phone_number',
    address: () => '/api/me/address',
    password: () => '/api/me/password',
    favorite: () => '/api/me/favorite',
    favoriteId: id => `/api/me/favorite/${id}`,
  },
  signInWithPassword: () => '/api/auth/self_authentication/sign_in',
  signUpWithPassword: () => '/api/auth/self_authentication/sign_up',
  user: () => `/api/user`,
  searchUser: () => `/api/users/search`,
  sendNoticeMailToUsers: () => `/api/users/send_notice_mail`,
  users: () => `/api/users`,
  salonAreas: () => '/api/salon_areas',
  salons: () => '/api/salons',
  salonsId: id => `/api/salons/${id}`,
  salonStar: () => '/api/salon/star',
  salonStarId: id => `/api/salon/star/${id}`,
  salonLogoImage: () => '/api/salon/logo_image',
  salonTopImage: () => '/api/salon/top_image',
  salonMemberships: () => '/api/salon_memberships',
  salonMembershipsId: id => `/api/salon_memberships/${id}`,
  salonMembershipRole: () => '/api/salon_membership/role',
  stylists: () => `/api/stylists`,
  stylistsId: id => `/api/stylists/${id}`,
  stylistStar: () => '/api/stylist/star',
  stylistStarId: id => `/api/stylist/star/${id}`,
  styleImage: () => '/api/stylist/image',
  stylistImage: () => '/api/stylist/stylist_image',
  stylistReservedSpots: () => '/api/stylist/reserved_spots',
  stylistReservedSpotsId: id => `/api/stylist/reserved_spots/${id}`,
  stylistReservedSpotsPolicies: () => `/api/stylist/reserved_spots_policies`,
  reserve: () => '/api/reserve',
  reserveId: id => `/api/reserve/${id}`,
  reserveFee: () => '/api/reserve_fee',
  stylistReserve: () => '/api/reserve_for_stylist',
  updateStylistReserve: id => `/api/reserve_for_stylist/${id}`,
  adminReserveIndex: () => '/api/admin/reserve',
  adminReserve: id => `/api/admin/reserve/${id}`,
  adminReservedSpots: () => '/api/admin/reserved_spots',
  adminUpdateReleaseTime: () => 'api/admin/reserved_spots/update_release_time',
  adminConciergeRequests: () => 'api/admin/concierge_requests',
  menu: () => `/api/stylist/menu/`,
  menuId: id => `/api/stylist/menu/${id}`,
  specialSpots: () => '/api/special_spots',
  specialSpotsId: id => `/api/special_spots/${id}`,
  conciergeMenus: () => '/api/concierge_menus',
  conciergeHairTypes: () => '/api/concierge_hair_types',
  conciergeHairLengths: () => '/api/concierge_hair_lengths',
  contactConcierge: () => '/api/contact_concierge',
  // test: (a, b) => `/api/${a}/${b}`, // NOTE: This is just an example
}

export type ExternalRoutes = '/api/auth/google' | '/api/auth/facebook' | '/api/auth/line'
