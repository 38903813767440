import unfetch from 'isomorphic-unfetch'
import { NextPageContext } from 'next'

export async function request(url: URL, requestInit: RequestInit, req?: NextPageContext['req']): Promise<Response> {
  const csrfToken = getCSRFTokenFromMetaTag()
  let options: RequestInit = {
    ...requestInit,
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      ...requestInit.headers,
    },
  }
  if (csrfToken) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        'x-csrf-token': csrfToken,
      },
    }
  }
  if (req && req.headers.cookie) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        cookie: req.headers.cookie,
      },
    }
  }
  if (req && req.headers['accept-language']) {
    options = {
      ...options,
      headers: {
        ...options.headers,
        'accept-language': req.headers['accept-language'],
      },
    }
  }
  if (options.body && options.body instanceof FormData) {
    // NOTE: Delete content-type header because it is automatically set by FormData
    delete (options.headers as any)['content-type']
  }
  return unfetch(url.toString(), options)
}

function getCSRFTokenFromMetaTag(): string | undefined {
  if (typeof document === 'undefined') {
    return
  }
  const dom = document.querySelector<HTMLMetaElement>('meta[name="csrf-token"]')
  if (dom === null) {
    return
  }
  return dom.content
}
