import * as React from 'react'
import NextLink from 'next/link'
import { ExternalRoutes, Route } from '../../routes'
import styled from 'styled-components'
import { colors } from '../../styleConstants'

export interface Props {
  readonly route?: Route
  readonly href?: ExternalRoutes
}

export function RawLink({ route, href, children, ...rest }: Props & React.AnchorHTMLAttributes<any>) {
  if (route) {
    return (
      <NextLink href={route.href} as={route.as}>
        <a {...rest}>{children}</a>
      </NextLink>
    )
  }
  return (
    <a href={href} {...rest}>
      {children}
    </a>
  )
}

export const Link = styled(RawLink)`
  text-decoration: none;
  color: ${colors.primary};
  cursor: pointer;

  &:hover,
  &:focus {
    text-decoration: underline;
  }

  &:not([href]):not([data-href]) {
    color: inherit;
    cursor: default;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
`
