export enum Language {
  English = 'en',
  Japanese = 'ja',
}

export function LanguageOfFormalName(language: Language): string {
  switch (language) {
    case Language.English:
      return 'English'
    case Language.Japanese:
      return '日本語'
  }
}

export const PreferredLanguageKeyInCookie = 'preferred_language'

export type I18nDirectory =
  | 'common'
  | 'root'
  | 'auth'
  | 'profile'
  | 'menu'
  | 'gallery'
  | 'explore'
  | 'stylists'
  | 'reset-password'
  | 'salons'
  | 'salons-member'
  | 'reservation'
  | 'reservation-history'
  | 'admin-dashboard'
  | 'admin-service-config'
  | 'admin-salons'
  | 'admin-salons-edit'
  | 'admin-stylists'
  | 'admin-stylists-edit'
  | 'admin-special-spots'
  | 'admin-users'
  | 'admin-reservations'
  | 'admin-reserved-spots'
  | 'admin-concierge-requests'
  | 'concierge'
