import getConfig from 'next/config'
import { APIRoute } from '../routes'

export function apiURL(path: APIRoute, ...params: string[]): URL {
  const { publicRuntimeConfig } = getConfig()
  let url = new URL(publicRuntimeConfig.baseApiUri)
  if (typeof window !== 'undefined') {
    url = new URL(window.location.origin)
  }
  url.pathname = path(...params)
  return url
}
