import { I18nCatalogue } from './types'

const ja: I18nCatalogue = {
  header: {
    language: 'Language',
    languageDialogTitle: '言語を選択してください',
    signIn: 'ログイン',
    signUp: '会員登録',
    signOut: 'サインアウト',
    profile: 'プロフィール',
  },
  footer: {
    help: 'ヘルプ',
    terms: '利用規約',
    privacy: 'プライバシー',
  },
  navigation: {
    home: 'ホーム',
    explore: '検索',
    calendar: 'カレンダー',
    reservationHistory: '予約履歴',
    inbox: '受信箱',
    profile: 'プロフィール',
    conversations: 'チャット',
    adminDashboard: '管理者ページ',
    salonsEdit: '美容室編集',
    salonsMember: '美容室在籍管理',
  },
  cancel: 'キャンセル',
  done: '完了',
  visited: '来店完了',
  abort: '来店キャンセル',
  delete: '削除',
  reload: 'リロード',
  edit: '編集',
  save: '保存',
  close: '閉じる',
  detail: '詳細をみる',
  end: '終了',
  reserveCteated: '予約完了時間',
  dayOfWeek: {
    sunday: '日',
    monday: '月',
    tuesday: '火',
    wednesday: '水',
    thursday: '木',
    friday: '金',
    saturday: '土',
  },
  ja: '日本語',
  en: '英語',
  creditSettlement: { title: 'クレジット決済', failedReadCardInfo: 'カード情報の読み取りに失敗しました' },
  excludingTax: '税込',
  minute: '分',
}

export default ja
